<template>
  <div class="page">
    <div class="page_body">
      <div class="top_bar"></div>
      <div class="user_card">
        <img class="user_bg" src="@/assets/personal/bg_01.png" alt @click="_toUserInfoPage">
        <div class="user_info">
          <img class="user_hat" src="@/assets/personal/icon_hat.png" alt>
          <img class="avatar mb10" :src="avatar" @click="_toUserInfoPage">
          <span class="nickname mb10">{{ nickname }}</span>
					<div class="card_footer">
						<div class="user_amount">
							<span>余额：</span>
							<img class="money_sym" src="@/assets/personal/money_sym.png" alt>
							<router-link :to="{path: '/wallet'}">{{amount}}</router-link>
						</div>
						<div class="user_invite_msg" v-clipboard:copy="_getInviteCode" @success="onCopy" v-if="!!_getInviteCode">
							<span>邀请码：</span>
							<span>{{_getInviteCode}}</span>
							<img class="icon_copy" src="@/assets/personal/copy.png" />
						</div>
					</div>
        </div>
        <div class="icon_signin">
          <router-link :to="{path: '/signin_list'}">
            <div class="cyclo" v-if="isshow"></div>
            <img src="@/assets/personal/icon_signin.png" alt>
          </router-link>
        </div>
        <div class="icon_invite">
          <router-link to="">
            <img src="@/assets/personal/msg.png" alt>
          </router-link>
        </div>
      </div>
      <div class="tl menuList">
        <div class="i-cell-group">
					<div class="i-cell-box">
            <van-cell is-link :to="{path: '/myOrder'}">
              <div class="cell_title" slot="title">
                <img class="cell_icon" src="@/assets/personal/icon_shenhe.png" alt>
                <span class="custom-text">订单</span>
              </div>
            </van-cell>
          </div>
          <div class="i-cell-box">
            <van-cell is-link :to="{path: '/history'}">
              <div class="cell_title" slot="title">
                <img class="cell_icon" src="@/assets/personal/icon_history.png" alt>
                <span class="custom-text">足迹</span>
              </div>
            </van-cell>
          </div>
          <div class="i-cell-box">
            <van-cell is-link :to="{path: '/about'}">
              <div class="cell_title" slot="title">
                <img class="cell_icon" src="@/assets/personal/icon_about.png" alt>
                <span class="custom-text">关于「淘街坊」</span>
              </div>
            </van-cell>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import ls from "@/libs/localstore";
import { getUserInfo, getUsergrade } from "@/api/user";
import { getShareLink, getFriendsCount } from "@/api/invite";
import { getOrderNotPaidCount } from "@/api/order";
import { getList as getSettingList } from "@/api/setting";
import { Cell, Dialog, Row, Col, CellGroup, Icon, Progress } from "vant";
import { getSigninList } from "@/api/signin";
import ICell from "@/components/Cell";
import VueClipboards from "vue-clipboards";
import {isMicroMessenger} from '@/utils/user-agent'

Vue.use(Cell)
  .use(Dialog)
  .use(Row)
  .use(Col)
  .use(CellGroup)
	.use(Icon)
	.use(VueClipboards)
  .use(Progress);

export default {
  name: "Personal",
  components: {
    StatusBar,
    NavBar,
    ICell
  },
  data() {
    return {
      isLogin: true,
      shareLink: "",
      show: false,
      signin_show: false,
      notPaidCount: 0,
      notReceivedCount: 0,
      notDeliveredCount: 0,
      friendsCount: 0,
      nickname: ls.getNickName(),
      avatar: ls.getAvatar(),
      amount: ls.getUserAmount(),
      tnl: ls.getUserTnl(),
      personal_invite_enable: 1,
      personal_wallet_enable: 1,
      personal_signin_enable: 1,
      is_wechat_installed: false,
      signin_list: [],
      isshow: false,
      level_image: "",
      level_title: "",
      next_level_title: "",
      next_level_tnl: "",
      remain_tnl: "",
      user_tnl_m: "",
      user_tnl: 0
    };
  },
  computed: {
    _showBtnBar() {
      return (
        this.personal_invite_enable &&
        this.personal_wallet_enable &&
        this.personal_signin_enable
      );
		},
		_getInviteCode() {
			let user_info = ls.getUserInfo();
			if(!!user_info) {
				return user_info.invite_code
			}
			return null
		}
	},
	mounted(){
		if(isMicroMessenger) {
			document.addEventListener('WeixinJSBridgeReady', function () {
				parent.WeixinJSBridge.call('hideOptionMenu')
			}, false)
			
			parent.WeixinJSBridge.call('hideOptionMenu')
		}
	},
  created() {
    this._loadUserInfo();
    this._loadNotPaidOrder();
    this.initList();
  },
  methods: {
		onCopy(e) {
      this.$toast("复制成功");
    },
    //跳转积分
    get_integral() {
      this.$router.push({ path: "/integral" });
    },
    _toOrderPage(page) {
      ls.setOrderPageTab(page);
      this.$router.push({ path: "/order_list" });
    },
    _loadFriendsCount() {
      let params = {
         
      };
      getFriendsCount(params).then(res => {
        if (res.errCode == 0) {
          this.friendsCount = res.data.count;
        }
      });
    },
    _toUserInfoPage() {
      if (this.isLogin) {
        this.$router.push({ path: "/userInfo" });
      } else {
        Vue.cookie.set("beforeLoginUrl", "/personal");
        this.$router.push({ path: "/login" });
      }
    },
    _loadUserInfo() {
      let token = ls.getToken();
      this.isLogin = false;
      if (!token) return false;
      getUserInfo()
        .then(res => {
          if (res.errCode == 0) {
            this.isLogin = true;
            ls.setUserInfo(res.data);

            this.amount = res.data.amount;
            if (res.data.nickname) {
              this.nickname = res.data.nickname;
            } else if (res.data.phone) {
              this.nickname = res.data.phone;
            } else {
              this.nickname = "大侠";
            }

            if (res.data.avatar) {
              this.avatar = res.data.avatar;
            }
          } else {
            this.isLogin = false;
          }
        })
        .catch(err => {
          this.isLogin = false;
        });
    },
    _loadSetting() {
      let params = {};
      getSettingList(params).then(res => {
        if (res.errCode == 0) {
          for (let i in res.data) {
            if (res.data[i].key == "personal_invite_enable") {
              this.personal_invite_enable = parseInt(res.data[i].val);
            }

            if (res.data[i].key == "personal_wallet_enable") {
              this.personal_wallet_enable = parseInt(res.data[i].val);
            }

            if (res.data[i].key == "personal_signin_enable") {
              this.personal_signin_enable = parseInt(res.data[i].val);
            }
          }
        }
      });
    },
    _loadShareLink() {
      let params = {
         
      };
      getShareLink(params).then(res => {
        if (res.errCode == 0) {
          this.shareLink = res.data.link;
        }
      });
    },
    shareCheck: function(scene) {
      if (scene == 0) {
        this.share("session");
      } else if (scene == 1) {
        this.share("timeline");
      }
    },
    share: function(scene) {
      let that = this;
      Wechat.share(
        {
          message: {
            title: "hi，我刚刚用淘街坊看视频抢红包赚了18元，快来抢红包吧~",
            thumb: "https://showdoc.quanminhy.cn/download/icon512.png",
            media: {
              type: window.Wechat.Type.WEBPAGE,
              webpageUrl: that.shareLink
            }
          },
          scene:
            scene == "session" ? Wechat.Scene.SESSION : Wechat.Scene.TIMELINE
        },
        function(reason) {
          that.$toast.success("分享成功");
        },
        function(reason) {
          that.$toast.fail("分享失败");
        }
      );
    },
    _signinClose: function() {
      this.signin_show = false;
    },
    _loadNotPaidOrder() {
      let params = {
         
      };

      getOrderNotPaidCount(params).then(res => {
        if (res.errCode == 0) {
          this.notPaidCount = parseInt(res.data.notPaidCount);
          this.notReceivedCount = parseInt(res.data.notReceivedCount);
          this.notDeliveredCount = parseInt(res.data.notDeliveredCount);
        }
      });
    },
    //是否签到
    initList() {
      let params = {
         
      };
      getSigninList(params).then(res => {
        if (res.errCode == 0) {
          this.signin_list = res.data;
        }
        this.signin_list.forEach((v, i) => {
          if (v.is_signin) {
            this.data = v.period;
          }
        });
        //	判断今天是否签到
        for (let i in this.signin_list) {
          if (!this.signin_list[i].is_signin && this.signin_list[i].is_today) {
            this.isshow = true;
            return;
          }
        }
      });
    },
    click_order() {
      this.$router.push({ path: "/torder_check" });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon_signin .cyclo {
  position: absolute;
  top: 2px;
  left: 26px;
  width: 6px;
  height: 6px;
  background: #f40337;
  border-radius: 20px;
}
.cyclo_two{
  position: absolute;
  top: 2px;
  right: 4px;
  width: 6px;
  height: 6px;
  background: #f40337;
  border-radius: 20px;
}
.page {
  background: #f9f9f9;
  padding-bottom: 50px;
}
.page_body {
  height: auto;
  flex: none;
  background: #fff;
}

.top_bar {
  padding: 7.5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_left {
  width: 96px;
  height: 31px;
}

.top_left img {
  width: 100%;
}

.top_right {
  width: 153px;
  height: 22px;
  background: rgba(243, 246, 248, 0.8);
  border-radius: 89px;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  color: #666666;
  text-align: center;
  line-height: 22px;
}

.user_card {
  margin: 0 16px;
  background-image: linear-gradient(-179deg, #f6e1ff 0%, #d6dfff 95%);
  height: 190px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px 0 rgba(213, 213, 213, 0.2);
  position: relative;
}

.user_bg {
  width: 100%;
}

.icon_signin {
  position: absolute;
  left: 20px;
  top: 20px;
  background: rgba(255, 255, 255, 0.5);
  width: 36px;
  height: 36px;
  border-radius: 36px;
  /* overflow: hidden; */
}

.icon_signin img {
  width: 36px;
}

.icon_invite img {
  width: 36px;
}

.icon_invite {
  position: absolute;
  right: 20px;
  top: 20px;
  background: rgba(255, 255, 255, 0.5);
  width: 36px;
  height: 36px;
  border-radius: 36px;
  /* overflow: hidden; */
}

.user_info {
	width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  overflow: hidden;
}
.user_hat {
  width: 16px;
  height: 8px;
  margin-bottom: 1px;
}
.cell_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nickname {
  font-size: 16px;
  color: #666666;
}

.card_footer {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.user_amount {
  padding: 0 15px;
  height: 31px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  color: #4d4d4d;
  line-height: 31px;
	display: flex;
	align-items: center;
	justify-self: space-around;
}
.user_amount a {
  color: #4d4d4d;
  text-decoration: none;
}
.user_amount span {
	font-size: 12px;
}

.user_invite_msg {
	padding: 0 15px;
  height: 31px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  color: #4d4d4d;
	display: flex;
	align-items: center;
	justify-self: space-around;
}
.user_invite_msg span {
	font-size: 12px;
}

.money_sym {
  width: 7px;
  height: 9px;
  margin-right: 5px;
}

.icon_copy {
	width: 15px;
	margin-left: 5px;
}

.fast_btn p {
  font-size: 13px;
  color: #fff;
}
.fast_btn .text_amount {
  display: inline-block;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}
.money {
  margin-left: -5px;
}

.sharePopup {
  padding: 50px 30px;
}
.sharePopup img {
  border: solid 1px #eee;
  border-radius: 5px;
}
.sharePopup p {
  color: #555;
}

.page .van-dialog {
  width: 100%;
  padding: 10px;
  background-color: transparent;
}

.cell-order-content {
  padding: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-around;
}

.order-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
}

.order-item .icon_box {
  color: #717171;
  position: relative;
}

.order-item .icon_box img {
  width: 100%;
}

.order-item .text {
  font-size: 12px;
  color: #717171;
}

.tab_info {
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f40337;
  border-radius: 20px;
  top: 5px;
  right: 5px;
  text-align: center;
  line-height: 10px;
  z-index: 10;
}

.brw {
  border-right: 1px solid #fff;
}

.i_text {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i_text .van-icon {
  font-size: 12px;
}

.i-cell-group {
  padding: 0 32px;
}
.i-cell-box {
  /* border-top: 1px solid #f1f1f1; */
  display: flex;
  flex-wrap: wrap;
}

.i-cell-box .van-cell {
  padding: 15px 0;
}

.cell_menu_box {
  padding: 15px 0;
  margin: 0 27px 0 28px;
  border-bottom: 1px solid #f8f8f8;
}

.cell_menu_btn {
  position: relative;
  width: 20%;
  height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.menu_icon {
  width: 55px;
  height: 44px;
}

.menu_icon img {
  width: 100%;
}

.menu_name {
  font-size: 12px;
  color: #666666;
  text-align: center;
}

.cell_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #4d4d4d;
}

.cell_icon {
  width: 24px;
  margin-right: 5px;
}
.member_header {
  display: flex;
  justify-content: space-between;
  margin: 0 17px 9px 22px;
  align-items: center;
}
.member_header_right {
  font-size: 12px;
  color: #666;
  padding: 2px 17px 2px 20px;
  background: #f6f6f6;
  border-radius: 89px;
}
.member_box {
  margin: 0 32px 0 33px;
  border-bottom: 1px solid #f8f8f8;
}
.member_img {
  width: 96px;
  height: 32px;
}
.member {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #808080;
  margin-top: 16px;
  margin-bottom: 22px;
}
.member_two {
  color: #bcbcbc;
}
.progess {
  margin: 9px 0 8px 0;
}
.progess .van-progress {
  height: 10px;
  border-radius: 100px;
  background: #f7f7f7;
  width: 316px;
}

.progess .van-progress >>> .van-progress__pivot {
  position: absolute;
  top: -11px;
  /* right: 14%; */
  right: 40px;
  width: 40px;
  /* height: 4PX; */
  padding: 0px 2px 0px 3px;
  border-radius: 9px 9px 0 9px;
  min-width: 0;
  /* padding: 0; */
  line-height: 16px;
  font-size: 10px;
  background: #ffb80d !important;
}
.integral {
  font-size: 10px;
  color: #ff9c9c;
  margin-left: 3px;
}
.member_footer {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #ffb80d;
  margin-bottom: 6px;
}
.progess >>> .van-progress__portion--with-pivot {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.advertising_box{
  /* height: 77px; */
  margin-top: 10px;
 
}
.advertising_box >>> .page_container{
 box-shadow:none;
}
.advertising_box >>> img{
  height: 100% !important;
}
</style>
