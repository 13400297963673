// 创建订单
export const orderCreate 					= (params) => window._http('/Orders-create', params)

// 支付订单
export const orderPay 						= (params) => window._http('/Orders-pay', params)

// 查询订单
export const orderQuery 					= (params) => window._http('/Orders-query', params)

// 创建订单
export const orderGenerate 				= (params) => window._http('/Orders-generate', params)

// 支付订单
export const orderPayment 				= (params) => window._http('/Orders-payment', params)

// 查询订单
export const orderInquire 				= (params) => window._http('/Orders-inquire', params)

// 获取订单列表
export const getList 							= (params) => window._http('/Orders-index', params)

// 取消订单
export const orderCancel 					= (params) => window._http('/Orders-cancel', params)

// 获取订单详情
export const getDetail 						= (params) => window._http('/Orders-detail', params)

// 确认收货
export const received 						= (params) => window._http('/Orders-received', params)

// 获取订单详情
export const getOrderItem 				= (params) => window._http('/Orders-getOrderItem', params)

// 获取助力订单
export const getOrderSupport 			= (params) => window._http('/OrderSupport-item', params)

// 获取未付款订单数量
export const getOrderNotPaidCount = (params) => window._http('/Orders-getNotPaidCount', params)

// 删除订单
export const deleteItem 					= (params) => window._http('/Orders-delete', params)

// 砍价
export const bargain 							= (params) => window._http('/OrderSupport-bargain', params)

// 获取订单信息
export const getOrderPaymentInfo 	= (params) => window._http('/Orders-getOrderPaymentInfo', params)
