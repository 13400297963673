<template>
	<div class="cell-container">
		<div class="cell-top" @click="_onClick">
			<div class="cell-title">{{ cell_title }}</div>
			<div class="cell-link">
				<span class="cell-value">{{ cell_value }}</span>
				<van-icon name="arrow"/>
			</div>
		</div>
		<slot name="cell-content"></slot>
	</div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'

Vue.use(Icon)

export default {
  components: {},
  props: {
    cell_title: {
      type: String,
      default: ''
    },
    cell_value: {
      type: String,
      default: ''
    },
    link_url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  computed: {},
  created () {},
  mounted () {},
  methods: {
    _onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.cell-container {
	border: 1px solid #F2F2F2;
	border-radius: 8px;
	overflow: hidden;
	background: #fff;
}
.cell-top {
	height: 45px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}
.cell-title {
	color: #2C2C2C;
}
.cell-link {
	color: #BFBFBF;
	display: flex;
	align-items: center;
}
.cell-link .van-icon{
	color: #AAAAAA
}
</style>
