// 获取签到列表
export const getSigninList 	= (params) => window._http('/Signin-index', params)

// 签到领取
export const doSign 				= (params) => window._http('/Signin-do', params)

// 补签
export const replenishSign 	= (params) => window._http('/Signin-replenish', params)

// 获取当天签到信息
export const getTodaySignin = (params) => window._http('/Signin-getTodaySignin', params)

// 查询用户信息
export const getuserlist 		= (params) => window._http('/User-info', params)

